import React, { useState, useEffect } from 'react';
import CardsFooter from '../components/Footers/CardsFooter';
import DemoNavbar from '../components/Navbars/DemoNavbar';
import { postMan } from '../services/utils';
import { Container, Row, Col } from 'reactstrap';
import MainLoader from '../components/MainLoader';
import LanguageMenu from '../components/LanguageMenu';
import CookieBanner from '../components/CookieBanner';
import MetaTags from 'react-meta-tags';
import { intlGlobal } from '../index';

export const CookiePolicy = () => {
    const [cookie, setCookie] = useState([]);
    useEffect(
        () => {
            const getData = async () => {
                const [dataCookie, errorCookie] = await postMan("get_content","POST", { data: {url:"cookies"} });
                if(errorCookie)
                  return;
                setCookie(dataCookie.data.result);
                window.scrollTo({top: 0,left: 0,behavior: 'smooth'})
              }
              getData();
        }, []
    )

    return(
        !cookie ? 
        <MainLoader />
        :
        <React.Fragment>
            <MetaTags>
                <title>{intlGlobal.formatMessage({id: "cookies.title"})}</title>
                <meta name="description" content={intlGlobal.formatMessage({id: "cookies.description"})} />
            </MetaTags>
            <DemoNavbar />
            <main className='mt-5 pt-5 legal-page'>
                <Container>
                    <h1 className='text-center'>{cookie.description}</h1>
                    <Row>
                        <Col md="12">
                            <div className='text-justify' dangerouslySetInnerHTML={{__html:cookie.html}}></div>
                        </Col>
                    </Row>
                </Container>
            </main>
            <LanguageMenu />
            <CookieBanner />
            <CardsFooter />
        </React.Fragment>
    )
}

export default CookiePolicy;