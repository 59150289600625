import React, { useState, useEffect, useRef, useContext } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Button, Row, Col, Container, Carousel, Form } from 'react-bootstrap';
import { etoast, makeValid, postMan, toast, translateBackendMessage, validateEmail, getLocale, parseLocaleURL } from '../services/utils';
import MetaTags from 'react-meta-tags';
import LanguageMenu from "../components/LanguageMenu";
import CookieBanner from "../components/CookieBanner";
import HCaptcha from '@hcaptcha/react-hcaptcha';
import environments from './../environments/environments';
import { FormattedMessage, useIntl } from 'react-intl';
import { HashLink } from "react-router-hash-link";
import LogoClean from './../assets/img/logo-clean.png'
import { intlGlobal, UserContext } from "../index.js";

const ForgotPassword = () => {
  const intl = useIntl();
  const user = useContext(UserContext);

  useEffect(
      () => {

          if(user.id)
              window.location.assign(parseLocaleURL());
      },[]
  )
  return (
    <>
    <MetaTags>
      <title>{intlGlobal.formatMessage({id: "forgot-password.title"})}</title>
      <meta name="description" content={intlGlobal.formatMessage({id: "forgot-password.description"})} />
    </MetaTags>
      <Row className="h-100 w-100 m-0 p-0">
        <Col className="h-100 col-lg-4 col-md-4 col-12 col-sm-12 m-0 p-0 d-none d-sm-block">
        <Carousel fade={true} >
            <Carousel.Item className="vh-100 carousel-img-1">
              <Carousel.Caption>
                <h3><FormattedMessage id="forgot.carousel.title1" /></h3>
                <p><FormattedMessage id="forgot.carousel.caption1" /></p>
              </Carousel.Caption>
            </Carousel.Item>
            <Carousel.Item className="vh-100 carousel-img-2">
              <Carousel.Caption>
                <h3><FormattedMessage id="forgot.carousel.title2" /></h3>
                <p><FormattedMessage id="forgot.carousel.caption2" /></p>
              </Carousel.Caption>
            </Carousel.Item>
            <Carousel.Item className="vh-100 carousel-img-3">
              <Carousel.Caption>
                <h3><FormattedMessage id="forgot.carousel.title3" /></h3>
                <p><FormattedMessage id="forgot.carousel.caption3" /></p>
              </Carousel.Caption>
            </Carousel.Item>
          </Carousel>
        </Col>
        <Col className="bg-white col-12 col-sm-12 col-md-8 pt-4">
          <div className="h-100 d-flex justify-content-center align-items-center">
            <ForgotBox intl={intl} />
          </div>
        </Col>
      </Row>
      <LanguageMenu />
      <CookieBanner />
    </>
  );
}

const ForgotBox = (props) =>{
    const intl = props.intl; 
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);

    const [errorEmail, setErrorEmail] = useState(false);
    const [errorCaptcha, setErrorCaptcha] = useState(false);

    const [token, setToken] = useState("");
    const captchaRef = useRef();
    const email = useRef();

    const validateForm = () => {
        let ret = true;
        if(!email.current.value || !validateEmail(email.current.value))
        {
            setErrorEmail(true);
            ret = false;
        }
        if(!token && environments.hCaptchaRequired)
        {
          setErrorCaptcha(true);
          ret = false;
        }
        return ret;
    }

    const resetPass = async (e) => {
        e.preventDefault();

        if(!validateForm())
            return false;

        setErrorCaptcha(false);
        setLoading(true);

        const [resp,error] = await postMan("forgot_password","POST",{ 
            data: {
                email: email.current.value,
            }, 
            token: environments.hCaptchaRequired ? token : "",
        });
        if(error)
        {
            etoast(intl.formatMessage({id: 'error.internal'}));
            if(environments.hCaptchaRequired)
              captchaRef.current.resetCaptcha();
            return setLoading(false);
        }
        const response = resp.data.result;

        if(response.error_msg)
        {
            etoast(response.error_msg)
            if(environments.hCaptchaRequired)
              captchaRef.current.resetCaptcha();
            return setLoading(false);
        }

        if(response.success_msg)
        {
            setLoading(false);
            toast(translateBackendMessage(response.success_msg));
            if(environments.hCaptchaRequired)
              captchaRef.current.resetCaptcha();
        }

        setLoading(false);
    }

    const handleVerificationSuccess = (token) => {
      setToken(token);
      setErrorCaptcha(false);
    }
    const goBack = () => {
        navigate("/login");
    }
  
  return(
    <div className="mx-auto col-sm-12 col-md-10 col-lg-9">
      <Container>
        <Row className="justify-content-center">
          <Col lg="12" md="12">
            <HashLink to="/#header"><img src={LogoClean} alt="Invoice cash factoring logo" className="app-logo" /></HashLink>
            <h4 className="mb-0 mt-3">
              <div><FormattedMessage id="forgot.header" /></div>
            </h4>
            <span className="pt-2"><FormattedMessage id="forgot.header-lead" /></span>

            <h6 className="mt-3"><FormattedMessage id="forgot.no-account" /><Link to="/register" className="text-primary"><FormattedMessage id="forgot.register-now" /></Link></h6>
            <hr className="mb-3" />
            <Form>
              <Row>
                <Col md={12}>
                    <Form.Group controlId="formBasicEmail" className={errorEmail ? `has-danger` : ``}>
                        <Form.Control isInvalid={errorEmail} type="email" placeholder={intl.formatMessage({id: 'email'})} ref={email} required autoComplete="email" minLength="10" maxLength="255" onChange={(event) => makeValid(event)} />
                        <Form.Control.Feedback type="invalid" >
                          <FormattedMessage id="error.required" />
                        </Form.Control.Feedback>
                    </Form.Group>
                </Col>
                <Col md={12} className="mt-2">
                {
                    environments.hCaptchaRequired ? 
                    <Form.Group className={errorCaptcha ? `form-captcha has-danger` : `form-captcha`}>
                        <HCaptcha
                            ref={captchaRef}
                            sitekey={environments.hCaptchaSiteKey}
                            onVerify={(token,ekey) => handleVerificationSuccess(token, ekey)}
                            languageOverride={getLocale()}
                        />
                        {
                            errorCaptcha ? 
                            <p className='text-danger'>
                            <FormattedMessage id="error.captcha" />
                            </p>
                            :
                            <></>
                        }
                    </Form.Group>
                    : <></>
                }
                </Col>
                <Col md={12}><hr style={{marginTop: "0"}} /></Col>
                <Col md={12} className=" d-flex justify-content-between">
                    <Button variant="outline-primary" onClick={() => goBack()}>
                    <span id="reg_text">
                      <FormattedMessage id="forgot.back" />
                    </span>
                    </Button>
                    {
                    loading ? 
                      <Button
                        className="btn-round"
                        color="default"
                        type="submit"
                        disabled>
                        <FormattedMessage id="loading" />
                        </Button>
                        :
                        <Button
                            className="btn-round"
                            color="default"
                            type="submit"
                            onClick={(event) => resetPass(event)}>
                        <FormattedMessage id="forgot.reset-now" />
                      </Button>
                    }
                </Col>
              </Row>
            </Form>
          </Col>
        </Row>
      </Container>
    </div>
  )
}

export default ForgotPassword;
