import { postMan } from "./utils.js";

export const fetchCompanies = async (value) => {
  if(!value || value.length < 2)
    return mapNothing();
    
  const [data,error] = await postMan('get_companies','POST', {data: { search: value }, timeout: 3000});

  if(error)
    return console.log(error.response.data);

  return data ? 
  data.data.result.map((company) => ({
    label: `${company.name}`,
    value: `${company.id}|${company.reg_number}`
  })) : 
  mapNothing();
}

const mapNothing = () => {
  return [].map(() => ({
    label: 'Nothing to display',
    value: ''
  }))
}