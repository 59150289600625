import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Button, Row, Col, Container, Carousel, Form } from 'react-bootstrap';
import MetaTags from 'react-meta-tags';
import LanguageMenu from "../components/LanguageMenu";
import CookieBanner from "../components/CookieBanner";
import { FormattedMessage, useIntl } from 'react-intl';
import { HashLink } from "react-router-hash-link";
import LogoClean from './../assets/img/logo-clean.png'
import { intlGlobal } from "../index.js";

const RegisterWarning = (props) => {
  const intl = useIntl();

  return (
    <>
    <MetaTags>
      <title>{intlGlobal.formatMessage({id: "register.warning-header"})}</title>
      <meta name="description" content={intlGlobal.formatMessage({id: "register.warning-header"})} />
    </MetaTags>
      <Row className="h-100 w-100 m-0 p-0">
        <Col className="h-100 col-lg-4 col-md-4 col-12 col-sm-12 m-0 p-0 d-none d-sm-block">
        <Carousel fade={true} >
            <Carousel.Item className="vh-100 carousel-img-1">
              <Carousel.Caption>
                <h3><FormattedMessage id="security-code.carousel.title1" /></h3>
                <p><FormattedMessage id="security-code.carousel.caption1" /></p>
              </Carousel.Caption>
            </Carousel.Item>
            <Carousel.Item className="vh-100 carousel-img-2">
              <Carousel.Caption>
                <h3><FormattedMessage id="security-code.carousel.title2" /></h3>
                <p><FormattedMessage id="security-code.carousel.caption2" /></p>
              </Carousel.Caption>
            </Carousel.Item>
            <Carousel.Item className="vh-100 carousel-img-3">
              <Carousel.Caption>
                <h3><FormattedMessage id="security-code.carousel.title3" /></h3>
                <p><FormattedMessage id="security-code.carousel.caption3" /></p>
              </Carousel.Caption>
            </Carousel.Item>
          </Carousel>
        </Col>
        <Col className="bg-white col-12 col-sm-12 col-md-8 pt-4">
          <div className="h-100 d-flex justify-content-center align-items-center">
            <RegisterWarningBox intl={intl} />
          </div>
        </Col>
      </Row>
      <LanguageMenu />
      <CookieBanner />
    </>
  );
}

const RegisterWarningBox = (props) =>{
    const intl = props.intl; 
    const navigate = useNavigate();

    const goBack = () => {
        navigate("/login");
    }

    const goForward = () => {
        navigate("/register");
    }
  
  return(
    <div className="mx-auto col-sm-12 col-md-10 col-lg-9">
      <Container>
        <Row className="justify-content-center">
          <Col lg="12" md="12">
            <HashLink to="/#header"><img src={LogoClean} alt="Invoice cash factoring logo" className="app-logo" /></HashLink>
            <h4 className="mb-0 mt-3">
              <div><FormattedMessage id="register.warning-header" /></div>
            </h4>

            <hr className="mb-3" />
            <Form>
              <Row>
                <Col md={12}>
                    <div>
                        <b>&#8226; </b><FormattedMessage id="register.warning-text-1" />
                        <br /><br />
                        <b>&#8226; </b><FormattedMessage id="register.warning-text-2" />
                    </div>
                    <br />
                </Col>
                <Col md={12}><hr style={{marginTop: "0"}} /></Col>
                <Col md={12} className=" d-flex justify-content-center">
                    <Button className="btn-round" color="default" type="submit" onClick={() => goForward()}>
                    <span id="reg_text1">
                      <FormattedMessage id="register.warning-acknowledge" />
                    </span>
                    </Button>
                </Col>
              </Row>
            </Form>
          </Col>
        </Row>
      </Container>
    </div>
  )
}

export default RegisterWarning;
