import React, { useState, createContext, useEffect, useMemo, Suspense } from "react";
import ReactDOM from "react-dom";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { postMan, getLocale } from "./services/utils";
import { createIntl, createIntlCache, IntlProvider } from 'react-intl';
//Pages
import Landing from "./pages/Landing.js";
import NotFound from './pages/NotFound.js';
import TermsAndConditions from "./pages/TermsAndConditions";
import PrivacyPolicy from "./pages/PrivacyPolicy";
import CookiePolicy from "./pages/CookiePolicy";
import AntiFraud from "./pages/AntiFraud";
import FAQ from './pages/FAQ';
import FAQD from './pages/FAQD';
import CorporateGovernance from './pages/CorporateGovernance.js';
import Login from './pages/Login.js';
import RegisterWarning from "./pages/RegisterWarning";
import Register from './pages/Register.js';
import ForgotPassword from './pages/ForgotPassword.js';
import ResetPassword from './pages/ResetPassword.js';
import SecurityCode from './pages/SecurityCode.js'
import MainLoader from "./components/MainLoader";
//User template
import User from "./services/user";
//Languages
import English from './locales/en.json';
import Romanian from './locales/ro.json';
//SCSS
import "./assets/scss/argon-design-system-react.scss?v1.1.0";
//import "antd/dist/antd.css"
import "antd/dist/antd.min.css";
import axios from "axios";


const cache = createIntlCache();
const formatIntl = (locale) => {
  switch (locale) {
    case "en-US":
      return English;
    case "en":
      return English;
    case "us":
      return English;
    case "ro":
      return Romanian;
    default:
      return Romanian;
  }
}

export const intlGlobal = createIntl({ locale: getLocale(), messages: formatIntl(getLocale())}, cache);
export const UserContext = createContext();

const App = () => {
  const locale = getLocale();
  const [lang, setLang] = useState(English);
  const user = useMemo(() => new User(), []);

  useEffect(() => {
      let id = window.setTimeout(function() {}, 0);
      while (id--) {
          window.clearTimeout(id);
      }
      switch (locale) {
        case "en-US":
          setLang(English);
          break;
        case "en":
          setLang(English);
          break;
        case "ro":
          setLang(Romanian);
          break;
        default:
          setLang(Romanian);
          break;
      }
     },[locale]
  )
  useEffect(
    () => {
      const getUser = async () => {
        const [data,error] = await postMan("is_logged_user","POST");
        if(error)
          return;
        if(data.data.result.id)
        {
          const usr = data.data.result;
          user.setUser(
            {
              id: usr.id,
              first_name: usr.first_name,
              last_name: usr.last_name,
              email: usr.email
            }
          );
        }
      }
      getUser();
    }, [user]
  )

  return (
    <IntlProvider locale={locale} messages={lang}>
      <UserContext.Provider value={user}>
        <BrowserRouter>
	  <Routes>
              <Route path="/" exact element={<Landing />} />
             // <Route path="/?intro_brok=:broker" exact element={<Landing />} />
              //<Route path="/broker/:broker/:cui" exact element={<Landing />} />
              <Route path="/terms-and-conditions" exact element={<TermsAndConditions />} />
              <Route path="/privacy-policy" exact element={<PrivacyPolicy />} />
              <Route path="/cookie-policy" exact element={<CookiePolicy />} />
              <Route path="/anti-fraud" exact element={<AntiFraud />} />
              <Route path="/frequently-asked-questions" exact element={<FAQ />} />
              <Route path="/frequently-asked-questions-documents" exact element={<FAQD />} />
              <Route path="/corporate-governance" exact element={<CorporateGovernance />} />
              <Route path="/login" exact element={<Login action="login" />} />
              <Route path="/register" exact element={<Register />} />
              <Route path="/register-info" exact element={<RegisterWarning />} />
              <Route path="/forgot-password" exact element={<ForgotPassword />} />
              <Route path="/security-code" exact element={<SecurityCode />} />
              <Route path="/reset-password/:sid/:token" exact element={<ResetPassword />} />
              <Route path="/verify-email/:sid/:token/:email" exact element={<Login action="verify-email" />} />
              <Route path='*' element={<NotFound />} />
            </Routes>
          </BrowserRouter>
      </UserContext.Provider>
    </IntlProvider>
  )
}

ReactDOM.render(
  <Suspense fallback={<MainLoader/>}>
    <App />
  </Suspense>,
  document.getElementById("root")
);
