import React from "react";
import { Link } from "react-router-dom";
import {NavItem, NavLink, Nav, Container, Row, Col} from "reactstrap";
import { FormattedMessage } from 'react-intl';
export const CardsFooter = () => {
    return (
      <>
        <footer className="footer has-cards">
          <Container>
            <hr />
            <Row className="align-items-center justify-content-md-between">
              <Col md="12">
                <Nav className="nav-footer justify-content-center">
                  <NavItem>
                    <NavLink
                      tag={Link}
                      to="/terms-and-conditions"
                    >
                      <FormattedMessage id="footer.tos" />
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      tag={Link}
                      to="/privacy-policy"
                    >
                      <FormattedMessage id="footer.gdpr" />
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      tag={Link}
                      to="/cookie-policy"
                    >
                      <FormattedMessage id="footer.cookies" />
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      tag={Link}
                      to="/anti-fraud"
                    >
                      <FormattedMessage id="footer.anti-fraud" />
                    </NavLink>
                  </NavItem>
                </Nav>
              </Col>
              <Col md="12" className="mt-3">
                <div className="copyright text-center" >
                  © {new Date().getFullYear()}{" "}
                  <Link
                    to="/"
                  >
                    Invoice Cash IFN
                  </Link>
                  .
                </div>
              </Col>
            </Row>
          </Container>
        </footer>
      </>
  );
}

export default CardsFooter;
