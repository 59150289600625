import React, { useState, useEffect } from 'react';
import CardsFooter from '../components/Footers/CardsFooter';
import MainLoader from '../components/MainLoader';
import DemoNavbar from '../components/Navbars/DemoNavbar';
import { postMan } from '../services/utils';
import { Accordion, Card, Button, Container } from 'react-bootstrap';
import LanguageMenu from '../components/LanguageMenu';
import MetaTags from 'react-meta-tags';
import CookieBanner from '../components/CookieBanner';
import { intlGlobal } from '..';

export const FAQ = () => {
    const [faq, setFaq] = useState([]);
    useEffect(
        () => {
            const getData = async () => {
                const [dataFAQ, errorFAQ] = await postMan("get_QNA","POST");
                if(errorFAQ)
                {
                    console.error(errorFAQ);
                    return;
                }
                  const filtered = dataFAQ.data.result.filter((doc) => isDocument(doc));
                  setFaq(filtered);
              }
              getData();
        }, []
    )
    const isDocument = (doc) => {
        return doc.document === "0";
    }
    const htmlDecode = (input) => {
        var e = document.createElement('div');
        e.innerHTML = input;
        return e.childNodes.length === 0 ? "" : e.childNodes[0].nodeValue;
    }

    return(
        <React.Fragment>
            <MetaTags>
                <title>{intlGlobal.formatMessage({id: "faq.title"})}</title>
                <meta name="description" content={intlGlobal.formatMessage({id: "faq.description"})} />
            </MetaTags>
            {
            !faq ?  
            <MainLoader />
            :
            <React.Fragment>
                <DemoNavbar />
                <main>
                    <Container className="mt-5 pt-5">
                        <Accordion defaultActiveKey="0">
                            {
                                faq.map(
                                    (item,key) => {
                                        return (
                                            <Card key={key}>
                                                <Card.Header>
                                                    <Accordion.Toggle as={Button} variant="link" eventKey={key.toString()} className="text-left">
                                                    {item.question}
                                                    </Accordion.Toggle>
                                                </Card.Header>
                                                <Accordion.Collapse eventKey={key.toString()}>
                                                    <Card.Body dangerouslySetInnerHTML={{__html:htmlDecode(item.answer)}}></Card.Body>
                                                </Accordion.Collapse>
                                            </Card>
                                        )
                                    }
                                )
                            }
                        </Accordion>
                    </Container>
                </main>
                <LanguageMenu />
                <CookieBanner />
                <CardsFooter />
            </React.Fragment>
            }   
        </React.Fragment>
    )
}

export default FAQ;