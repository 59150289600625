import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faMoneyBillAlt, faUserPlus, faCloudUploadAlt, faChartBar } from '@fortawesome/free-solid-svg-icons';
import { FormattedMessage } from 'react-intl';
import { Link } from "react-router-dom";

const HowItWorks = () => {

    return(
    <section className="section-lg bg-secondary pb-0" id="how-it-works">
        <Container>
            <Row className="justify-content-center text-center">
            <Col lg="8">
                <h2 className="display-3"><FormattedMessage id="how-it-works.header" /></h2>
                <p className="lead text-muted"><FormattedMessage id="how-it-works.header-lead" /></p>
            </Col>
            </Row>
        </Container>
        <section className="section section-lg bg-secondary" style={{minHeight: "70vh"}}>
            <div className="pset">
                <Container fluid>
                    <Row className="listar-feature-items">
                        <Col xs={12} sm={6} md={6} lg={3} className="listar-feature-item-wrapper listar-feature-with-image listar-height-changed" data-aos="fade-zoom-in" data-aos-group="features" data-line-height="25.2px">
                            <div className="listar-feature-item listar-feature-has-link">

                            <Link to="/register" />

                            <div className="listar-feature-item-inner">

                                <div className="listar-feature-right-border"></div>

                                <div className="listar-feature-block-content-wrapper">
                                <div className="listar-feature-icon-wrapper">
                                    <div className="listar-feature-icon-inner">
                                    <div className='d-flex justify-content-center align-items-center'>
                                        <FontAwesomeIcon className='listar-image-icon icon-how-it-works text-primary' icon={faUserPlus} />
                                    </div>
                                    </div>
                                </div>

                                <div className="listar-feature-content-wrapper" style={{paddingTop: "0px"}}>

                                    <div className="listar-feature-item-title listar-feature-counter-added">
                                    <span><span>01</span>
                                        <FormattedMessage id="how-it-works.s1" /></span>
                                    </div>

                                    <div className="listar-feature-item-excerpt text-justify"><FormattedMessage id="how-it-works.s1-lead" />.</div>

                                </div>
                                </div>
                            </div>
                            </div>
                            <div className="listar-feature-fix-bottom-padding listar-fix-feature-arrow-button-height"></div>
                        </Col>

                        <Col xs={12} sm={6} md={6} lg={3} className="listar-feature-item-wrapper listar-feature-with-image listar-height-changed" data-aos="fade-zoom-in" data-aos-group="features" data-line-height="25.2px">
                            <div className="listar-feature-item listar-feature-has-link">

                            <Link to="/register" />

                            <div className="listar-feature-item-inner">

                                <div className="listar-feature-right-border"></div>

                                <div className="listar-feature-block-content-wrapper">
                                <div className="listar-feature-icon-wrapper">
                                    <div className="listar-feature-icon-inner">
                                        <div className='d-flex justify-content-center align-items-center'>
                                            <FontAwesomeIcon className='listar-image-icon icon-how-it-works text-primary' icon={faCloudUploadAlt} />
                                        </div>
                                    </div>
                                </div>

                                <div className="listar-feature-content-wrapper" style={{paddingTop: "0px"}}>

                                    <div className="listar-feature-item-title listar-feature-counter-added">
                                    <span><span>02</span>
                                    <FormattedMessage id="how-it-works.s2" /> </span>
                                    </div>

                                    <div className="listar-feature-item-excerpt text-justify"><FormattedMessage id="how-it-works.s2-lead" /></div>
                                    </div>
                                </div>
                            </div>
                            </div>
                            <div className="listar-feature-fix-bottom-padding listar-fix-feature-arrow-button-height"></div>
                        </Col>

                        <Col xs={12} sm={6} md={6} lg={3} className="listar-feature-item-wrapper listar-feature-with-image listar-height-changed" data-aos="fade-zoom-in" data-aos-group="features" data-line-height="25.2px">
                            <div className="listar-feature-item listar-feature-has-link">

                            <Link to="/register" />

                            <div className="listar-feature-item-inner">

                                <div className="listar-feature-right-border"></div>

                                <div className="listar-feature-block-content-wrapper">
                                <div className="listar-feature-icon-wrapper">
                                    <div className="listar-feature-icon-inner">
                                        <div className='d-flex justify-content-center align-items-center'>
                                            <FontAwesomeIcon className='listar-image-icon icon-how-it-works text-primary' icon={faChartBar} />
                                        </div>
                                    </div>
                                </div>

                                <div className="listar-feature-content-wrapper" style={{paddingTop: "0px"}}>

                                    <div className="listar-feature-item-title listar-feature-counter-added">
                                    <span><span>03</span>
                                    <FormattedMessage id="how-it-works.s3" /> </span>
                                    </div>

                                    <div className="listar-feature-item-excerpt text-justify"><FormattedMessage id="how-it-works.s3-lead" /></div>
                                    </div>
                                </div>
                            </div>
                            </div>
                            <div className="listar-feature-fix-bottom-padding listar-fix-feature-arrow-button-height"></div>
                        </Col>

                        <Col xs={12} sm={6} md={6} lg={3} className="listar-feature-item-wrapper listar-feature-with-image listar-height-changed" data-aos="fade-zoom-in" data-aos-group="features" data-line-height="25.2px">
                            <div className="listar-feature-item listar-feature-has-link">

                            <Link to="/register" />

                            <div className="listar-feature-item-inner">

                                <div className="listar-feature-right-border"></div>

                                <div className="listar-feature-block-content-wrapper">
                                <div className="listar-feature-icon-wrapper">
                                    <div className="listar-feature-icon-inner">
                                        <div className='d-flex justify-content-center align-items-center'>
                                            <FontAwesomeIcon className='listar-image-icon icon-how-it-works text-success' icon={faMoneyBillAlt} />
                                        </div>
                                    </div>
                                </div>

                                <div className="listar-feature-content-wrapper" style={{paddingTop: "0px"}}>

                                    <div className="listar-feature-item-title listar-feature-counter-added">
                                    <span><span>04</span>
                                    <FormattedMessage id="how-it-works.s4" /> </span>
                                    </div>

                                    <div className="listar-feature-item-excerpt text-justify"><FormattedMessage id="how-it-works.s4-lead" /></div>
                                </div>
                                </div>
                            </div>
                            </div>
                            <div className="listar-feature-fix-bottom-padding listar-fix-feature-arrow-button-height"></div>
                        </Col>
                    </Row>
                </Container>
            </div>
        </section>
    </section>
    )
}
export default HowItWorks;