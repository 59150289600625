import React from 'react';
import { Container, Row, Col, Button } from 'reactstrap';
import { FormattedMessage } from 'react-intl';
import { HashLink } from 'react-router-hash-link';
import videoLanding from '../../assets/landing.mov'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBook, faMoneyBillWave } from '@fortawesome/free-solid-svg-icons';

export const Header = () => {
    return(
      <div className="position-relative">
        <section className="section section-lg section-shaped" id="header" style={
          {
            paddingBottom:"0",
            paddingTop:"0", 
            minHeight:"100vh", 
            maxHeight:"100vh", 
            height:"100vh",
            backgroundColor:"black"
          }}>
            <video playsInline autoPlay loop muted className="videoTag w-100 h-100" 
              style=
                {{
                  position:"absolute",
                  top:"0", 
                  left: "0", 
                  minWidth:"100vh", 
                  minHeight:"100vh",
                  maxHeight:"100vh", 
                  objectFit:"contain",
                  display:"block",
                  margin:"0 auto",
                  backgroundColor:"black"
                }}>
              <source src={videoLanding} type='video/mp4' />
            </video>
          <Container fluid className="position-relative w-100 h-100">
            <Container fluid className="d-flex h-100 w-100 justify-content-center align-items-end">
              <div className="col px-0 mb-2">
                <Row>
                  <Col lg="12">
                    <h1 className="display-3 text-white text-center">
                      <FormattedMessage id="header.h1" />
                      <span className='header-subheader d-sm-block d-none'><FormattedMessage id="header.span" /></span>
                    </h1>
                  </Col>
                </Row>
                <Row>
                  <Col lg="12">
                    <div className="btn-wrapper text-center">
                      <Button
                        className="services-btn btn-icon mb-3 mb-sm-0 d-none d-sm-inline"
                        color="info"
                        tag={HashLink}
                        to="/#services"
                      >
                        <span className="btn-inner--icon mr-1">
                          <FontAwesomeIcon icon={faBook} />
                        </span>
                        <span className="btn-inner--text"><FormattedMessage id="header.services" /></span>
                      </Button>
                      <Button
                        className="btn-white btn-icon mb-3 mb-sm-0 ml-1"
                        color="default"
                        tag={HashLink}
                        to="/#calculator"
                      >
                        <span className="btn-inner--icon mr-1">
                          <FontAwesomeIcon icon={faMoneyBillWave} />
                        </span>
                        <span className="btn-inner--text">
                          <FormattedMessage id="header.offer" />
                        </span>
                      </Button>
                    </div>
                  </Col>
                </Row>
              </div>
            </Container>
          </Container>
        </section>
      </div>
    )
}

export default Header;