import React from 'react';

export const CorporateGovernance = () => {
    return(
        <>
        
        </>
    )
}

export default CorporateGovernance;