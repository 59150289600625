import React, { Suspense, useEffect } from "react";
import { HTTP_GET, postManSuspender, setCookie, saveOrigination } from "../services/utils.js";
import DemoNavbar from "../components/Navbars/DemoNavbar.js";
import CardsFooter from "../components/Footers/CardsFooter.js";
import MainLoader from '../components/MainLoader.js';
import Header from '../components/Landing/Header';
import LanguageMenu from '../components/LanguageMenu.js';
import CookieBanner from '../components/CookieBanner.js';
import MetaTags from 'react-meta-tags';
import HowItWorks from "../components/Landing/HowItWorks.js";
import { intlGlobal } from '../index.js';
import { useParams, useLocation } from "react-router";

const Advantages = React.lazy(() => import('./../components/Landing/Advantages'));
const PartOfStripe = React.lazy(() => import('./../components/Landing/PartOfStripe'));
const Services = React.lazy(() => import('./../components/Landing/Services'));
const Calculator = React.lazy(() => import('./../components/Landing/Calculator'));
const Factoring = React.lazy(() => import('./../components/Landing/Factoring'));
const Business = React.lazy(() => import('./../components/Landing/Business'));
const MediaSection = React.lazy(() => import('./../components/Landing/MediaSection'));
const TeamSection = React.lazy(() => import('./../components/Landing/TeamSection'));
const Testimonials = React.lazy(() => import('./../components/Landing/Testimonials'));
const Partners = React.lazy(() => import('./../components/Landing/Partners'));
const Contact = React.lazy(() => import('./../components/Landing/Contact'));
const SocialMediaLinks = React.lazy(() => import('./../components/Landing/SocialMediaLinks'));

const dataCalculator= postManSuspender("get_calculator_data","POST");
const dataTeam= postManSuspender("get_team_data","POST");
const dataTestimonials = postManSuspender("get_testimonials","POST");
const dataMedia = postManSuspender("get_media","POST");

const Landing = () => {

  const { broker } = useParams();
  const location = useLocation();
  const calculatorData = dataCalculator;
  const teamData = dataTeam;
  const testimonialsData = dataTestimonials;
  const mediaData = dataMedia;

  useEffect(() => {
    setTimeout(
      () => {
        const { hash } = window.location;
        if (hash) {
          const id = hash.replace('#', '');
          const element = document.getElementById(id);
          if (element) element.scrollIntoView({ block: 'start', behavior: 'smooth' });
        }
      },500
    )
  },[])
  useEffect(
    () => {
      if(broker){
        const [ _, isBroker, brokerCode, cui] = location.pathname.split('/');
        Promise.resolve(saveOrigination(brokerCode, cui));
        window.history.replaceState({}, document.title, "/");

        setCookie("IC_INTROD_BROKER",broker,1);
      }else{
        //Try to catch the old wy
        const broker_old_way = HTTP_GET("intro_brok");
        if(broker_old_way && broker_old_way !== "")
          setCookie("IC_INTROD_BROKER",broker_old_way,1);
      }
      
    }, [broker]
  )


    return (
      <Suspense fallback={<MainLoader/>}>
        <MetaTags>
                <title>{intlGlobal.formatMessage({id: "landing.title"})}</title>
                <meta name="description" content={intlGlobal.formatMessage({id: "landing.description"})} />
        </MetaTags>
        <DemoNavbar landing={true} />
        <main>
          <Header />
          <PartOfStripe />
          <HowItWorks />
          <Suspense fallback={<div>Loading ....</div>}>
            <Advantages />
            <Services />
            //<Calculator data={calculatorData.read()} />
            <Factoring />
            <Business />
            //<MediaSection data={mediaData.read()} />
            //<TeamSection data={teamData.read()} />
           // <Testimonials data={testimonialsData.read()} />
            <Partners />
            <Contact />
            <SocialMediaLinks />
          </Suspense>
        </main>
        <LanguageMenu />
        <CookieBanner />
        <CardsFooter />
      </Suspense>
    );
  }

export default Landing;
