
import React, { useState, useEffect, useContext } from "react";
import { Link, useNavigate } from "react-router-dom";
import { HashLink } from 'react-router-hash-link';
import Headroom from "headroom.js";
import { Button, UncontrolledCollapse, DropdownMenu, DropdownToggle, UncontrolledDropdown, Media, NavbarBrand, Navbar, NavItem, Nav, Container, Row, Col, Collapse } from "reactstrap";
import { FormattedMessage } from 'react-intl';
import { UserContext } from "../../index.js";
import { parseLocaleURL } from "../../services/utils.js";
import i1 from '../../assets/img/brand/logo-white-cropped.png';
import i2 from '../../assets/img/brand/logo-clean-2.png';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRight, faBook, faChevronRight, faComment, faFileInvoice, faLightbulb, faListAlt, faMapPin, faMoneyBillWaveAlt, faNewspaper, faQuestion, faRetweet, faUsers } from "@fortawesome/free-solid-svg-icons";


const DemoNavbar = (props) =>  {
  const navigate = useNavigate();
  const user = useContext(UserContext);
  const [collapseClasses, setCollapseClasses] = useState("");
  const [isOpen, setIsOpen] = useState(false);
  useEffect(
    () => {
      const headroom = new Headroom(document.getElementById("navbar-main"));
      headroom.init();
    },[]
  )

  const onExiting = () => setCollapseClasses("collapsing-out");

  const onExited = () => setCollapseClasses("");
  const goHome = () => {
    if(user.id)
    {
      window.location.assign(parseLocaleURL())
    }else{
      navigate("/login");
    }
  };

  const toggle = () => setIsOpen(!isOpen);
  const mobileToggle = () => {
    if( /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent) ) {
     setIsOpen(!isOpen);
    };
  };
    return (
      <>
        <header className="header-global">
          <Navbar
            className={`navbar-main navbar-primary headroom ${props.landing ? 'navbar-landing navbar-transparent' : 'navbar-not-landing navbar-transparent'}`}
            expand="lg"
            id="navbar-main"
          >
            <Container>
              <NavbarBrand className="mr-lg-5" to="/#header" tag={HashLink} smooth>
                <img
                  alt="..."
                  src={i1}
                />
              </NavbarBrand>
              <button className="navbar-toggler" id="navbar_global" onClick={toggle}>
                <span className="navbar-toggler-icon" />
              </button>
              <Collapse
                toggler="#navbar_global"
                navbar
                className={collapseClasses}
                onExiting={onExiting}
                onExited={onExited}
                isOpen={isOpen}
              >
                <div className="navbar-collapse-header">
                  <Row>
                    <Col className="collapse-brand" xs="6">
                      <Link to="/">
                        <img
                          alt="..."
                          src={i2}
                        />
                      </Link>
                    </Col>
                    <Col className="collapse-close" xs="6">
                      <button className="navbar-toggler" id="navbar_global" onClick={toggle}>
                        <span style={{pointerEvents:"none"}} />
                        <span style={{pointerEvents:"none"}} />
                      </button>
                    </Col>
                  </Row>
                </div>
                <Nav className="navbar-nav-hover align-items-lg-center" navbar>
                  <UncontrolledDropdown nav>
                    <DropdownToggle nav>
                      <FontAwesomeIcon icon={faChevronRight} className="d-lg-none mr-1" />
                      <span className="nav-link-inner--text"><FormattedMessage id="navbar.who-are-we"/></span>
                    </DropdownToggle>
                    <DropdownMenu className="dropdown-menu-xl">
                      <div className="dropdown-menu-inner">
                        <Media
                          className="d-flex align-items-center"
                          onClick={mobileToggle}
                          tag={HashLink}
                          to="/#how-it-works"
                          smooth
                        >
                          <div className="icon icon-shape bg-gradient-primary rounded-circle text-white">
                            <FontAwesomeIcon icon={faBook} />
                          </div>
                          <Media body className="ml-3">
                            <h6 className="heading text-primary mb-md-1">
                            <FormattedMessage id="navbar.how-it-works"/>
                            </h6>
                            <p className="description d-none d-md-inline-block mb-0">
                            <FormattedMessage id="navbar.how-it-works-lead"/>
                            </p>
                          </Media>
                        </Media>
                        <Media
                          className="d-flex align-items-center"
                          onClick={mobileToggle}
                          tag={HashLink}
                          to="/#why-us"
                          smooth
                        >
                          <div className="icon icon-shape bg-gradient-primary rounded-circle text-white">
                            <FontAwesomeIcon icon={faQuestion} />
                          </div>
                          <Media body className="ml-3">
                            <h6 className="heading text-primary mb-md-1">
                            <FormattedMessage id="navbar.why-us"/>
                            </h6>
                            <p className="description d-none d-md-inline-block mb-0">
                            <FormattedMessage id="navbar.why-us-lead"/>
                            </p>
                          </Media>
                        </Media>
                        <Media
                          className="d-flex align-items-center"
                          onClick={mobileToggle}
                          tag={HashLink}
                          to="/#services"
                          smooth
                        >
                          <div className="icon icon-shape bg-gradient-primary rounded-circle text-white">
                            <FontAwesomeIcon icon={faListAlt} />
                          </div>
                          <Media body className="ml-3">
                            <h6 className="heading text-primary mb-md-1">
                            <FormattedMessage id="navbar.services"/>
                            </h6>
                            <p className="description d-none d-md-inline-block mb-0">
                            <FormattedMessage id="navbar.services-lead"/>
                            </p>
                          </Media>
                        </Media>
                        <Media
                          className="d-flex align-items-center"
                          onClick={mobileToggle}
                          tag={HashLink}
                          to="/#calculator"
                          smooth
                        >
                          <div className="icon icon-shape bg-gradient-success rounded-circle text-white">
                            <FontAwesomeIcon icon={faMoneyBillWaveAlt} />
                          </div>
                          <Media body className="ml-3">
                            <h6 className="heading text-primary mb-md-1">
                              <FormattedMessage id="navbar.offer"/>
                            </h6>
                            <p className="description d-none d-md-inline-block mb-0">
                             <FormattedMessage id="navbar.offer-lead"/>
                            </p>
                          </Media>
                        </Media>
                      </div>
                    </DropdownMenu>
                  </UncontrolledDropdown>
                  <UncontrolledDropdown nav>
                    <DropdownToggle nav>
                      <FontAwesomeIcon icon={faChevronRight} className="d-lg-none mr-1" />
                      <span className="nav-link-inner--text"><FormattedMessage id="navbar.about"/></span>
                    </DropdownToggle>
                    <DropdownMenu className="dropdown-menu-xl">
                      <div className="dropdown-menu-inner">
                        <Media
                          className="d-flex align-items-center"
                          onClick={mobileToggle}
                          tag={HashLink}
                          to="/#about-factoring"
                          smooth
                        >
                          <div className="icon icon-shape bg-gradient-primary rounded-circle text-white">
                            <FontAwesomeIcon icon={faQuestion} />
                          </div>
                          <Media body className="ml-3">
                            <h6 className="heading text-primary mb-md-1">
                              <FormattedMessage id="navbar.about-us"/>
                            </h6>
                            <p className="description d-none d-md-inline-block mb-0">
                              <FormattedMessage id="navbar.about-us-lead"/>
                            </p>
                          </Media>
                        </Media>
                        <Media
                          className="d-flex align-items-center"
                          onClick={mobileToggle}
                          tag={HashLink}
                          to="/#team"
                          smooth
                        >
                          <div className="icon icon-shape bg-gradient-primary rounded-circle text-white">
                            <FontAwesomeIcon icon={faUsers} />
                          </div>
                          <Media body className="ml-3">
                            <h6 className="heading text-primary mb-md-1">
                              <FormattedMessage id="navbar.team"/>
                            </h6>
                            <p className="description d-none d-md-inline-block mb-0">
                              <FormattedMessage id="navbar.team-lead"/>
                            </p>
                          </Media>
                        </Media>
                        <Media
                          className="d-flex align-items-center"
                          onClick={mobileToggle}
                          tag={HashLink}
                          to="/#partners"
                          smooth
                        >
                          <div className="icon icon-shape bg-gradient-warning rounded-circle text-white">
                            <FontAwesomeIcon icon={faRetweet} />
                          </div>
                          <Media body className="ml-3">
                            <h5 className="heading text-warning mb-md-1">
                              <FormattedMessage id="navbar.partners"/>
                            </h5>
                            <p className="description d-none d-md-inline-block mb-0">
                              <FormattedMessage id="navbar.partners-lead"/>
                            </p>
                          </Media>
                        </Media>
                      </div>
                    </DropdownMenu>
                  </UncontrolledDropdown>
                  <UncontrolledDropdown nav>
                    <DropdownToggle nav>
                      <FontAwesomeIcon icon={faChevronRight} className="d-lg-none mr-1" />
                      <span className="nav-link-inner--text"><FormattedMessage id="navbar.media"/></span>
                    </DropdownToggle>
                    <DropdownMenu className="dropdown-menu-xl">
                      <div className="dropdown-menu-inner">
                        <Media
                          className="d-flex align-items-center"
                          onClick={mobileToggle}
                          tag={HashLink}
                          to="/#media"
                          smooth
                        >
                          <div className="icon icon-shape bg-gradient-primary rounded-circle text-white">
                            <FontAwesomeIcon icon={faNewspaper} />
                          </div>
                          <Media body className="ml-3">
                            <h6 className="heading text-primary mb-md-1">
                              <FormattedMessage id="navbar.meadia-appearances"/>
                            </h6>
                            <p className="description d-none d-md-inline-block mb-0">
                              <FormattedMessage id="navbar.meadia-appearances-lead"/>
                            </p>
                          </Media>
                        </Media>
{/*                         <Media
                          className="d-flex align-items-center"
                          tag={Link}
                          to="/corporate-governance"
                        >
                          <div className="icon icon-shape bg-gradient-primary rounded-circle text-white">
                            <i className="ni ni-building" />
                          </div>
                          <Media body className="ml-3">
                            <h6 className="heading text-primary mb-md-1">
                              <FormattedMessage id="navbar.corporate-governance"/>
                            </h6>
                            <p className="description d-none d-md-inline-block mb-0">
                              <FormattedMessage id="navbar.corporate-governance-lead"/>
                            </p>
                          </Media>
                        </Media> */}
                        <Media
                          className="d-flex align-items-center"
                          onClick={mobileToggle}
                          tag={HashLink}
                          to="/#testimonials"
                          smooth
                        >
                          <div className="icon icon-shape bg-gradient-warning rounded-circle text-white">
                            <FontAwesomeIcon icon={faComment} />
                          </div>
                          <Media body className="ml-3">
                            <h5 className="heading text-warning mb-md-1">
                            <FormattedMessage id="navbar.testimonials"/>
                            </h5>
                            <p className="description d-none d-md-inline-block mb-0">
                              <FormattedMessage id="navbar.testimonials-lead"/>
                            </p>
                          </Media>
                        </Media>
                      </div>
                    </DropdownMenu>
                  </UncontrolledDropdown>
                  <UncontrolledDropdown nav>
                    <DropdownToggle nav>
                      <FontAwesomeIcon icon={faChevronRight} className="d-lg-none mr-1" />
                      <span className="nav-link-inner--text"><FormattedMessage id="navbar.suport"/></span>
                    </DropdownToggle>
                    <DropdownMenu className="dropdown-menu-xl">
                      <div className="dropdown-menu-inner">
                        <Media
                          className="d-flex align-items-center"
                          tag={Link}
                          to="/frequently-asked-questions"
                        >
                          <div className="icon icon-shape bg-gradient-primary rounded-circle text-white">
                            <FontAwesomeIcon icon={faLightbulb} />
                          </div>
                          <Media body className="ml-3">
                            <h6 className="heading text-primary mb-md-1">
                              <FormattedMessage id="navbar.faq"/>
                            </h6>
                            <p className="description d-none d-md-inline-block mb-0">
                              <FormattedMessage id="navbar.faq-lead"/>
                            </p>
                          </Media>
                        </Media>
                        <Media
                          className="d-flex align-items-center"
                          tag={Link}
                          to="/frequently-asked-questions-documents"
                        >
                          <div className="icon icon-shape bg-gradient-primary rounded-circle text-white">
                            <FontAwesomeIcon icon={faFileInvoice} />
                          </div>
                          <Media body className="ml-3">
                            <h6 className="heading text-primary mb-md-1">
                              <FormattedMessage id="navbar.faqd"/>
                            </h6>
                            <p className="description d-none d-md-inline-block mb-0">
                              <FormattedMessage id="navbar.faqd-lead"/>
                            </p>
                          </Media>
                        </Media>
                        <Media
                          className="d-flex align-items-center"
                          onClick={mobileToggle}
                          tag={HashLink}
                          to="/#contact"
                          smooth
                        >
                          <div className="icon icon-shape bg-gradient-warning rounded-circle text-white">
                            <FontAwesomeIcon icon={faMapPin} />
                          </div>
                          <Media body className="ml-3">
                            <h5 className="heading text-warning mb-md-1">
                              <FormattedMessage id="navbar.contact"/>
                            </h5>
                            <p className="description d-none d-md-inline-block mb-0">
                              <FormattedMessage id="navbar.contact-lead"/>
                            </p>
                          </Media>
                        </Media>
                      </div>
                    </DropdownMenu>
                  </UncontrolledDropdown>
                </Nav>
                <Nav className="align-items-lg-center ml-lg-auto" navbar>
                  <NavItem className="d-lg-block ml-lg-4">
                    <Button
                      className="btn-neutral btn-icon"
                      color="default"
                      onClick={goHome}
                    >
                      <span className="btn-inner--icon">
                        <FontAwesomeIcon icon={faArrowRight} className="mr-2" />
                      </span>
                      <span className="nav-link-inner--text ml-1">
                        <FormattedMessage id="navbar.platform"/>
                      </span>
                    </Button>
                  </NavItem>
                </Nav>
              </Collapse>
            </Container>
          </Navbar>
        </header>
      </>
    )
}

export default DemoNavbar;
